.crud-table {
  background: $white;
  padding: 30px;
  border-radius: 10px;
}

.crud {
  width: 100% !important;
  td {
    border-bottom: 1px solid #71a1d4;
  }

  table {
    width: 100% !important;
    border-radius: 10px;
  }
  .tabletr-first {
    background: white;
    color: black;
    font-weight: bold;
    border-radius: 10px;

    th {
      padding: 5px;
    }
  }
  .tabletr-last {
    background: #343d55;
    color: white;
    border-radius: 10px;
    td {
      padding: 5px;
    }
  }
}

.crud-button {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
  border-color: #124d81;

  button {
    background-color: #124d81 !important;
    border-color: #124d81 !important;
    color: white;
    padding: 10px 15px;

    // &:hover,
    // &:active {
    //   pointer-events: none;
    // }
  }
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.btn-secondary {
  border-color: #004dad !important;
  background-color: #0976ff !important;
}

.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
  background-color: #006df3 !important;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-tabs > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-1b0bdye).ant-tabs > div > .ant-tabs-nav {
  position: unset !important;
}

.create-form {
  label {
    font-size: 16px;
    color: black;
    font-weight: 600;
    margin-bottom: 7px;
  }
  // input {
  //   width: 470px;
  // }
}

.create-form-content {
  padding: 28px;
  background-color: white;
  border-radius: 10px;
  max-width: 900px;
  margin: auto;
}

.switch {
  border-radius: 8px;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-upload-wrapper
  .ant-upload-drag
  .ant-upload {
  padding: 0;
}

.upload-button {
  background: none !important;
  border: none !important;
}

// TYPE TABLE

.type-table {
  background: #283046;
  padding: 30px;
  border-radius: 10px;
}

.type-button {
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  border-color: #124d81;

  button {
    background-color: #124d81 !important;
  }
}

.button-edit {
  background: rgb(53, 53, 235);
  color: white;
  border: none !important;
  border-radius: none !important;
  padding: 8px 16px !important;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-upload-wrapper .ant-upload {
  width: 100%;
}

.create-form {
  :where(.css-dev-only-do-not-override-1b0bdye).ant-btn-default {
    width: 100%;
  }
}

.custom-input {
  width: 100%;
  border-radius: 4px; /* Add border radius for rounded corners */
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  border-radius: 6px;
  transition: all 0.2s;
}

.custom-input:focus {
  border-color: #1677ff;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
  background-color: #ffffff;
}

/* Adjust padding and font size as needed */
.custom-input input {
  padding: 8px;
  font-size: 16px;
}

/* Style for prefix and suffix icons */
.custom-input .ant-input-prefix,
.custom-input .ant-input-suffix {
  color: #1890ff; /* Change color as needed */
}
