/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "flatpickr/dist/flatpickr.css";

button {
  border-radius: 6px;
}

.text-label {
  color: #b3b3bf !important;
}

.main-menu.menu-light .navigation > li ul .active {
  background: none;
  box-shadow: none;
}

.btn-flat-primary {
  box-shadow: 3px 3px 29px 6px rgba(0, 0, 0, 0.05);
}

.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 163px;
}

.main-menu .navbar-header {
  height: 6.45rem;
}

.vertical-layout .main-menu .navigation a.active {
  background: white;
  box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  color: #124d81;
}

.main-menu.menu-dark .navigation > li .active {
  background: #090d22;
  color: #124d81;
}

.main-menu.menu-dark .navigation li a {
  color: #124d81;
}

.header-navbar .navbar-container {
  padding: 1.8rem 1.4rem;
}

.navbar-sticky .app-content {
  padding: calc(2rem + 6.45rem) 2rem 0 2rem !important;
}

.auth-wrapper {
  background: white;
}

.auth-inner {
  .card {
    .card-body {
    }
  }
  &:before {
    background-image: none !important;
  }
  &:after {
    background-image: none !important;
  }
}

.content-header-title {
  border-right: none !important;
}

.react-dataTable {
  padding: 20px;
}

.react-dataTable .rdt_TableBody .rdt_TableRow {
  color: #6e6b7b;
  border: 1px solid #f2f2f2;
  margin: 4px 0;
  border-radius: 10px;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow {
  background-color: #fff !important;
  border: none !important;
}

.primary-ligh-bg {
  background-color: #eff5ff !important;
  svg {
    color: #124d81 !important;
  }
}

.danger-ligh-bg {
  background-color: #fef3f5 !important;
  svg {
    color: #e71d36 !important;
  }
}

.btn-icon {
  #fileButton {
    position: absolute;
    left: 0;
    width: 52px;
    height: 42px;
    opacity: 0;
  }
}

.btn-link {
  padding: 0;
  background-color: #fff !important;
  border: none;
  color: #6e6b7b !important;

  &:hover {
    background-color: white !important;
  }
}

.w-label {
  width: 270px;
  min-width: 250px !important;
  display: inline-block;
}

.form-control {
  //width: auto;
  padding: 16px 14px;
  border-radius: 10px;
}

.custom-input-item {
  padding: 9px 24px;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
}

.select__control {
  border-radius: 10px !important;
  height: 54.3px !important;
}

.custom-avatar {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-col-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
}

.custom-input-checkbox {
  border: 1px solid #d8d6de;
  border-radius: 10px;
  padding: 16px 14px;
}

.rdt_ExpanderRow {
  padding-right: 100px;
  padding-left: 100px;
}

.custom-expanded-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  padding: 15px 27px;
  color: #030229;
}

.rc-input-number-input-wrap {
  input {
    width: 60px;
    text-align: right;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    padding: 9px 6px;
    caret-color: transparent;
    cursor: pointer;
  }
}
.rc-input-number {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  text-align: right;
  width: 100px;
  margin-left: 20px;
  margin-right: 20px;
}

.rdw-editor-main {
  border: 1px solid #d8d6de;
  border-radius: 10px;
  padding: 12px 20px;
  margin-top: 20px;
  height: 400px !important;
}

.news {
  &-list {
    overflow-y: scroll;
    height: 650px;
    padding-top: 40px;
    padding-right: 20px;

    .btn-flat-secondary.dropdown-toggle {
      padding: 0;
      padding-left: 12px;
    }

    .btn-flat-secondary.dropdown-toggle::after {
      background-image: none;
    }

    &__icon {
      position: absolute;
      top: -30px;
      right: 0;
    }
    &__item {
      min-height: 100px;
      border-radius: 10px;
      background: #ffffff;
      box-shadow: 3px 3px 29px 6px rgba(0, 0, 0, 0.08);
      position: relative;
      padding: 10px 20px;
      margin-bottom: 50px;
      cursor: pointer;

      img {
        top: -40px;
        width: 153px;
        border-radius: 10px;
        position: absolute;
      }
      .content {
        margin-left: 177px;
      }
    }

    &__item.active {
      background: #124d81;
      .content {
        div {
          .content-title {
            color: #fff;
          }

          .content-date {
            color: #e2e2e2;
          }
        }
      }
    }

    &__item.active {
      .content {
        background: #124d81;

        div {
          .content-title {
            color: #fff;
          }

          .content-date {
            color: #e2e2e2;
          }
        }
      }
    }
  }

  .card-header {
    padding: 0;
    .card-img {
      width: 100%;
      height: 370px;
      position: relative;
      background: #f2f2f2;
      display: flex;

      .upload-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        width: 71px;
        height: 41px;
        background: #124d81;
        border-radius: 0px 28px 0px 0px;
        cursor: pointer;
      }

      #fileButton {
        position: absolute !important;
        left: 0;
        height: 100%;
        width: 88%;
        cursor: pointer;
        font-size: 0;
        opacity: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .card-control {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 370px;
    background: #fff !important;

    .flt-input {
      display: none;
    }
  }

  .news-lang {
    color: #929292;
    font-size: 16px;
    padding-left: 12px;
    padding: 10px 0;
  }

  .news-title {
    border-left: 2px solid #124d81;
    padding-left: 20px;
    margin-top: 10px;
  }

  .news-content {
    //padding: 0 20px;
  }
}

.sms-age {
  display: flex;
  justify-content: center;
  input {
    width: 80px;
  }
}

.sms-filters {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 3px 3px 15px -1px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    padding: 14px 15px;
    .label {
      font-weight: 400;
      font-size: 20px;
      color: #777777;
    }
  }
}

.users-list {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 3px 3px 15px -1px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    padding: 14px 15px;
    cursor: pointer;
    .label {
      font-weight: 400;
      font-size: 12px;
      color: #777777;
    }
  }
}

.templates-list {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 15px;
    cursor: pointer;
    input {
      margin-left: 0 !important;
    }
    .label {
      width: 95%;
      padding: 14px 16px;
      font-weight: 500;
      font-size: 18px;
      color: #777777;
      background: #f9fafc;
      border: 1px solid #e3eaf1;
      border-radius: 12px;
    }
  }
}

.banners {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(276px, 276px));
  grid-row-gap: 40px;
  justify-items: center;
  .banner {
    width: 276px;
    height: 364px;
    border-radius: 5px;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    &-buttons {
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translate(-50%);
    }

    &:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #efefef;
      cursor: pointer;

      #fileButton {
        position: absolute !important;
        left: 0;
        height: 100%;
        width: 88%;
        cursor: pointer;
        font-size: 0;
        opacity: 0;
      }
    }
  }
}

.filters-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-column-gap: 20px;
  justify-items: flex-start;
}

.rating {
  width: 80%;
  &-item {
    display: grid;
    grid-template-columns: 3fr 6fr 2fr;
    align-items: center;
    margin-bottom: 10px;
    &__rate {
      span {
        margin-bottom: 0 !important;
      }
    }
    &__progress {
    }
  }
}

.rating-passive {
  &-item {
    display: grid;
    grid-template-columns: 4fr 6fr 1fr 1fr;
    align-items: center;
    margin-bottom: 24px;
  }
}

.rating-rate {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  h1 {
    font-size: 96px;
  }
}

.rating-users {
  height: 600px;
  overflow: scroll;
  padding: 34px 22px;
  &__item {
    display: grid;
    grid-template-columns: 10fr 2fr;
    align-items: center;
    padding: 18px 20px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    margin-bottom: 10px;

    .content {
      margin-left: 0 !important;
    }

    .action {
      display: grid;
      justify-items: flex-end;
      grid-row-gap: 4px;
      span {
        margin-bottom: 4px !important;
      }
    }
  }
}

.rounded-btn {
  width: 44px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  box-shadow: 3px 3px 15px -1px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  cursor: pointer;

  svg {
    transform: rotate(-90deg);
  }
}

.history {
  padding-bottom: 10px;
  &-tabs {
    display: flex;
    justify-content: stretch;

    &__button {
      font-size: 1rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      flex-grow: 1;
      padding: 12px;
      box-shadow: 0px 3px 30px 2px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background: white;
      transition: all ease-in-out 0.2s;
      margin-right: 12px;

      &.active {
        background: #3b5998;
        color: white;
      }

      &.disabled {
        background: #e3e3e3;
        color: white;
      }
    }
  }

  &-tables {
    background: #ffffff;
    box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 4px;
    //padding-top: 40px !important;
    height: 600px !important;

    &__wrapper {
      overflow-y: scroll;
      height: 600px;
      position: relative;

      .table-header-bg {
        //background: red;
        //width: 83.8%;
        //height: 38px;
        //top: 220.9px;
        //position: fixed;
        //box-sizing: border-box;
        //border-radius: 5px 5px 0px 0px;
        //border-bottom: 1px solid #ebe9f1;
        //background: #F9FAFB;
        //width: 100%;
        //height: 37.3px;
        //background: red;
        ////background: #F9FAFB;
        //position: absolute;
        //top: -33px;
        //// transform: translate(100%, 2px);
        //border-radius: 5px 5px 0px 0px;
        //border-bottom: 1px solid #ebe9f1;
      }
    }

    .ReactTable .rt-thead {
    }

    .table-responsive {
    }

    table {
      tbody {
        tr {
          td:first-child {
            text-align: left;
          }

          td {
            text-align: center;
          }
        }
      }

      thead {
        // position: fixed;
        //top: 220px;

        tr {
          th {
            // position: sticky;
            top: 0;
            font-weight: 400;
            color: #898b9d;
            text-transform: none;
            background: #f9fafb !important;
          }
        }
      }
    }

    table > tbody > tr > td:nth-child(1) {
      button {
        padding: 6px 12px;
        min-width: 90px;
        font-size: 1rem;
      }

      button:first-child {
        margin-right: 10px !important;
      }
    }
  }
}
