.language-create {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
}

.ant-tabs-nav-wrap {
  display: none !important;
}

.language-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d2d7da;
  Button {
    width: 30%;
    border-radius: 5px;
  }
}

.langs-button {
  border: none !important;
  padding: 10px 10px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

[dir] .btn-outline-secondary:not(:disabled):not(.disabled):active,
[dir] .btn-outline-secondary:not(:disabled):not(.disabled).active,
[dir] .btn-outline-secondary:not(:disabled):not(.disabled):focus {
  background-color: rgb(53, 53, 235) !important;
  color: white;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):focus {
  color: #ffffff;
}
